import {
  model,
  Model,
  tProp,
  types,
} from 'mobx-keystone';
import { ApiClient } from 'utils/api-client';
import { apiCtx } from './contexts/api';
import { Accounts } from './domain/account/account';
import { Identity } from './domain/identity';
import { Leaderboard } from './domain/leaderboard/leaderboard';
import { TradeEvents } from './domain/trade-events/trade-events';
import { UI } from './domain/ui/ui';

@model('root')
export class RootStore extends Model({
  leaderboard: tProp(types.model(Leaderboard)),
  tradeEvents: tProp(types.model(TradeEvents)),
  identity: tProp(types.model(Identity)),
  accounts: tProp(types.model(Accounts)),
  ui: tProp(types.model(UI)),
}) {
  protected onInit(): void {
    apiCtx.set(this, this.api);
  }
  api = new ApiClient();

  onLogin() {
    this.leaderboard.fetchTraders();
    this.accounts.fetchAccounts();
  }
}

import { FollowedTraderDto } from '@core/models';
import {
  _await,
  ExtendedModel,
  fromSnapshot,
  model,
  modelFlow,
  tProp,
  types,
} from 'mobx-keystone';
import { MainModel } from 'store/utils/base-model';
import { FollowedTrader } from './followed-trader';
import { computed } from 'mobx';

@model('leaderboard')
export class Leaderboard extends ExtendedModel(MainModel, {
  followedTraders: tProp(types.array(types.model(FollowedTrader)), () => []),
  isInitiallyFetched: tProp(types.boolean, false),
}) {
  @computed
  get activeTraders() {
    return this.followedTraders.filter(({ isActive }) => isActive);
  }

  @computed
  get disabledTraders() {
    return this.followedTraders.filter(({ isActive }) => !isActive);
  }

  @modelFlow
  *fetchTraders() {
    const followedTraders = yield* _await(this.api.fetch('binance-leaderboard/followed-traders'));
    this.followedTraders = fromSnapshot(followedTraders);
    this.isInitiallyFetched = true;
  }

  @modelFlow
  *addFollowedTrader(body: FollowedTraderDto) {
    const followedTrader = yield* _await(this.api.create('binance-leaderboard/followed-trader', body));
    this.followedTraders.push(fromSnapshot(followedTrader));
  }

  async fetchSelectedFollowersPositions(selectedId?: string, doNotShowInflight?: boolean) {
    if (!this.isInitiallyFetched) {
      await this.fetchTraders();
    }
    return this.followedTraders.find(({ id }) => id === selectedId)?.fetchPositions(doNotShowInflight);
  }

  findTraderById(selectedId?: string) {
    return this.followedTraders.find(({ id }) => id === selectedId);
  }
}

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import IconButton from '@mui/joy/IconButton';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store/StoreContext';


export const LogoutButton = observer(() => {
  const { identity } = useStore();
  const navigate = useNavigate();
  const logout = async () => {
    await identity.logout();
    navigate('/login');
  };

  return (
    <IconButton size="sm" variant="plain" color="neutral" onClick={logout}>
      <LogoutRoundedIcon />
    </IconButton>
  );
});

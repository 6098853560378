import { PropsWithChildren, ReactNode } from 'react';
import { Box, Stack } from '@mui/joy';
import { PageHeader, PageHeaderProps } from 'components/PageHeader';

export type ContentSidebarLayoutProps = PropsWithChildren<{ sidebarContent: ReactNode }> & PageHeaderProps

export const ContentSidebarLayout = ({ children, title, headerContentRight, sidebarContent }: ContentSidebarLayoutProps) => (
  <>
    <Box sx={{ px: { xs: 2 } }}>
      <PageHeader
        title={title}
        headerContentRight={headerContentRight}
      />
    </Box>
    <Stack
      flex={1}
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      height="100%"
    >
      <Box
        id="main-content"
        sx={{
          flex: '1 0',
          overflow: 'auto',
          px: { xs: 2 },
        }}
      >
        {children}
      </Box>
      <Box sx={{
        width: 200,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        overflow: 'auto',
        paddingBottom: 3,
        flexBasis: 'content',
      }}
      >
        {sidebarContent}
      </Box>
    </Stack>
  </>
);

import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/StoreContext';
import { extractFormData } from 'utils/extract-form-data';
import { FollowedTraderDto } from '@core/models';

export const FollowNewTraderModal = observer(() => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { leaderboard } = useStore();
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = extractFormData<FollowedTraderDto>(event);
    await leaderboard.addFollowedTrader(data);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="neutral"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        Follow new trader
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Follow new trader</DialogTitle>
          <DialogContent>Enter new traders name and ID</DialogContent>
          <form
            onSubmit={onSubmit}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input name="name" required />
              </FormControl>
              <FormControl>
                <FormLabel>Encrypted UID</FormLabel>
                <Input name="encryptedUid" required />
              </FormControl>
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
});

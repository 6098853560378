import { computed } from 'mobx';
import {
  _await,
  DataModel,
  ExtendedModel,
  fromSnapshot,
  idProp,
  Model,
  model,
  modelAction,
  modelFlow,
  prop,
  tProp,
  types,
} from 'mobx-keystone';
import { MainModel } from 'store/utils/base-model';
import { formatCurrency } from 'utils/formatCurrency';
import { formatDecimalisedPercentage } from 'utils/formatPercentage';
import { Position } from './trader-position';

@model('performance-stat')
export class PerformanceStat extends DataModel({
  periodType: prop<'DAILY' | 'WEEKLY' | 'MONTHLY' | 'ALL'>(),
  statisticsType: prop<'ROI' | 'PNL'>(),
  value: prop<number>(),
  rank: prop<number>(),
}) {}

@model('trader-stats')
export class TraderStats extends Model({
  performance: tProp(types.unchecked<PerformanceStat[]>()),
  balance: prop<number>(),
  totalTrades: prop<number>(),
  wins: prop<number>(),
  losses: prop<number>(),
  winRate: prop<number>(),
  totalPercent: prop<number>(),
  avgWinPercent: prop<number>(),
  avgLossPercent: prop<number>(),
  pnl: prop<number>(),
}) {
  @computed
  get formattedBalance() {
    return formatCurrency(this.balance);
  }

  @computed
  get formattedWinRate() {
    return formatDecimalisedPercentage(this.winRate);
  }

  @computed
  get formattedTotalPercent() {
    return formatDecimalisedPercentage(this.totalPercent);
  }

  @computed
  get formattedAvgWinPercent() {
    return formatDecimalisedPercentage(this.avgWinPercent);
  }

  @computed
  get formattedAvgLossPercent() {
    return formatDecimalisedPercentage(this.avgLossPercent);
  }

  @computed
  get formattedPnl() {
    return formatCurrency(this.pnl);
  }

  @computed
  get isProfitable() {
    return Math.sign(this.pnl) === 1;
  }
}

@model('followed-trader')
export class FollowedTrader extends ExtendedModel(MainModel, {
  id: idProp,
  name: prop<string>(),
  encryptedUid: prop<string>(),
  positions: tProp(types.array(types.model(Position)), () => []),
  isSelected: tProp(types.boolean, false),
  isFetchingPositions: tProp(types.boolean, false),
  isFetchingStats: tProp(types.boolean, false),
  stats: tProp(types.maybe(types.model(TraderStats))),
  isActive: tProp(types.boolean),
}) {
  get binanceUrl() {
    return `https://www.binance.com/en/futures-activity/leaderboard/user/um?encryptedUid=${this.encryptedUid}`;
  }

  @modelAction
  toggle() {
    this.isSelected = !this.isSelected;
  }

  @modelFlow
  *fetchStats() {
    this.isFetchingStats = true;
    const stats = yield* _await(this.api.fetch(`binance-leaderboard/followed-trader/${this.encryptedUid}/stats`));
    this.stats = new TraderStats(stats);
    this.isFetchingStats = false;
  }

  @modelFlow
  *fetchPositions(doNotShowInflight?: boolean) {
    if (!doNotShowInflight) {
      this.isFetchingPositions = true;
    }

    try {
      const positions = yield* _await(this.api.fetch(`binance-leaderboard/fetch-positions/${this.encryptedUid}`));
      this.positions = fromSnapshot(positions);
    } catch(err) {
      console.log('Position fetch error', err);
    }

    if (!doNotShowInflight) {
      this.isFetchingPositions = false;
    }
  }

  @modelFlow
  *remove() {
    yield* _await(this.api.delete(`binance-leaderboard/followed-trader/${this.id}`));
    this.isDeleted = true;
  }

  @modelFlow
  *toggleActiveState() {
    const payload = yield* _await(this.api.update(`binance-leaderboard/followed-trader/${this.id}/active`));
    this.isActive = payload.isActive;
  }
}

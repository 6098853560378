import { Delete } from '@mui/icons-material';
import { Button, IconButton } from '@mui/joy';
import DialogTitle from '@mui/joy/DialogTitle';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { success } from 'constants/toast-configs';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AccountProp } from 'types/account/account';

export const DeleteAccountButton = observer(({ account, isIconButton }: AccountProp & { isIconButton?: boolean }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const confirmDelete = async () => {
    await account.remove();
    setOpen(false);
    navigate('/main/accounts');
    toast(`Account ${account.name} successfully deleted.`, success);
  };

  const button = isIconButton ? (
    <IconButton
      aria-label="Delete"
      size="sm"
      color="danger"
      onClick={() => setOpen(true)}
    >
      <Delete />
    </IconButton>
  ) : (
    <Button
      variant="outlined"
      color="danger"
      startDecorator={<Delete />}
      onClick={() => setOpen(true)}
    >
      Delete account
    </Button>
  );

  return (
    <React.Fragment>
      {button}
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>
            Confirm deletion of
            {account.name}
          </DialogTitle>
          <Button color="danger" type="submit" onClick={confirmDelete}>Confirm</Button>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
});

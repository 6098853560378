import { computed } from 'mobx';
import {
  model,
  Model,
  prop,
} from 'mobx-keystone';

@model('trader-position')
export class Position extends Model({
  symbol: prop<string>(),
  entryPrice: prop<number>(),
  markPrice: prop<number>(),
  pnl: prop<number>(),
  roe: prop<number>(),
  amount: prop<number>(),
  updateTimeStamp: prop<number>(),
  tradeBefore: prop<boolean>(),
  long: prop<boolean>(),
  short: prop<boolean>(),
  leverage: prop<number>(),
}) {
  @computed
  get formattedValue() {
    return `${(this.markPrice * this.amount).toFixed(2)} USDT`;
  }

  @computed
  get formattedRoe() {
    return (this.roe * 100).toFixed(2) + '%';
  }

  @computed
  get isProfitable() {
    return Math.sign(this.roe) === 1;
  }
}

import { Box, Card, CardContent, Sheet, Table, Typography } from '@mui/joy';
import { ExtraInfoCard } from 'components/ExtraInfoCard/ExtraInfoCard';
import { Symbol } from 'components/Symbol';
import { observer } from 'mobx-react-lite';
import { FollowedTrader } from 'store/domain/leaderboard/followed-trader';
import { Position } from 'store/domain/leaderboard/trader-position';

export const TraderPositionsTable = observer(({ trader }: { trader: FollowedTrader }) => {
  if (trader.positions.length === 0) {
    return (
      <Box display={'flex'} justifyContent="center" alignItems={'center'} height={'100%'}>
        <Card variant="plain">
          <CardContent>
            <Typography level="title-md">Trader has no active positions</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <>
      <Sheet sx={{ paddingBottom: 4 }}>
        <Table size="lg">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Qty</th>
              <th>Value</th>
              <th>Entry price</th>
              <th>Mark price</th>
              <th>PNL</th>
              <th>ROE</th>
            </tr>
          </thead>
          <tbody>
            {trader?.positions.map(position => (
              <Row position={position} key={position.symbol} />
            ))}
          </tbody>
        </Table>
      </Sheet>

    </>
  );
});

export const Row = observer(({
  position: {
    symbol,
    entryPrice,
    markPrice,
    pnl,
    formattedRoe,
    amount,
    long,
    leverage,
    formattedValue,
    isProfitable,
  },
}: { position: Position }) => (
  <tr>
    <td>
      <Symbol symbol={symbol} leverage={leverage} isLong={long} />
    </td>
    <td>
      {amount}
    </td>
    <td>
      {formattedValue}
    </td>
    <td>
      {entryPrice}
    </td>
    <td>
      {markPrice}
    </td>
    <td>
      <ExtraInfoCard title="PnL" description={pnl} descriptionColor={isProfitable ? 'success' : 'danger'} />
    </td>
    <td>
      <ExtraInfoCard title="PnL" description={formattedRoe} descriptionColor={isProfitable ? 'success' : 'danger'} />
    </td>
  </tr>
));


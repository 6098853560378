import { List, ListItem, ListItemButton } from '@mui/joy';
import { Typography } from '@mui/joy';
import { Link } from 'components/Link';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/StoreContext';

export const AccountList = observer(() => {
  const { accounts: { accounts } } = useStore();

  return (
    <List sx={{ gap: 0.5 }}>
      {accounts.filter(({ isDeleted }) => !isDeleted).map(({ id, name }) => (
        <ListItem
          key={id} sx={{
            mt: 0.5,
            marginLeft: 2,
          }}
        >
          <ListItemButton>
            <Link to={`/main/account/${id}`} underline="none" color="neutral">
              <Typography level="title-sm">
                {name}
              </Typography>
            </Link>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
});

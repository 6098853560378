import { Box, Button, Card, CardActions, CardOverflow, Checkbox, Divider, Sheet, Stack, Typography, useTheme } from '@mui/joy';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useStore } from 'store/StoreContext';
import { AccountProp } from 'types/account/account';

export type FollowedTraders = {
  followedTraders: string[]
}

export const FollowedTraders = observer(({ account }: AccountProp) => {
  const { leaderboard: { activeTraders } } = useStore();
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    reset,
    formState,
    watch,
  } = useForm<FollowedTraders>({ defaultValues: { followedTraders: account.followedTraderIds } });

  const onSubmit: SubmitHandler<FollowedTraders> = async data => {
    await account?.updateFollowedTraders({ traders: data.followedTraders });
    console.log('account.followedTraderIds', account.followedTraderIds);
    reset({ followedTraders: account.followedTraderIds });
  };

  useEffect(() => {
    reset({ followedTraders: account.followedTraderIds });
  }, [account]);

  const followedTraderSelectedValues = watch('followedTraders');


  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mb: 2 }}>
          <Typography level="title-md">Followed traders</Typography>
        </Box>
        <Divider />
        <Stack
          gap={1} paddingY={2} sx={{
            '& > div': {
              p: 2,
              borderRadius: 'md',
              display: 'flex',
            },
          }}
        >
          {activeTraders.map(({ id, name }) => {
            const isChecked = followedTraderSelectedValues.includes(id);

            const checkStyle = {
              inset: -1,
              border: '1px solid',
              borderColor: theme.vars.palette.primary[400],
            };

            return (
              <Sheet
                key={id}
                variant="outlined"
                sx={{
                  padding: 4,
                  paddingBottom: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                  ...(isChecked ? checkStyle : {}),
                }}
              >
                <Checkbox
                  {...register('followedTraders')}
                  checked={isChecked}
                  label={name}
                  overlay
                  value={id}
                />
              </Sheet>
            );
          })}
        </Stack>
        <CardOverflow sx={{
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
        >
          <CardActions sx={{
            alignSelf: 'flex-end',
            pt: 2,
            paddingBottom: 0,
          }}
          >
            <Button size="md" variant="solid" disabled={!formState.isDirty} type="submit" loading={account.isUpdatingFollowedTraders}>
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      </form>
    </Card>
  );
});



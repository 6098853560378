import { ColorPaletteProp } from '@mui/joy';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { PropsWithChildren } from 'react';

export type ExtraInfoCardProps = PropsWithChildren<{
  title: string;
  description: string | number;
  descriptionColor?: ColorPaletteProp;
  variant?: 'plain' | 'solid' | 'outlined' | 'soft'
}>

export const ExtraInfoCard = ({ title, description, descriptionColor, variant = 'plain' }: ExtraInfoCardProps) => (
  <Card size="sm" variant={variant}>
    <CardContent>
      <Typography level="title-md">
        {title}
      </Typography>
      <Typography color={descriptionColor}>
        {description}
      </Typography>
    </CardContent>
  </Card>
);

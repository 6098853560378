"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeEventsDto = exports.PagingQuery = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const constants_1 = require("../binance-leaderboard/constants");
const transformStrParm = ({ value }) => {
    const arr = value.split(',');
    if (arr.length === 1 && arr[0] === '') {
        return [];
    }
    return arr;
};
class PagingQuery {
    take;
    skip;
    type;
    trader;
}
exports.PagingQuery = PagingQuery;
exports.TradeEventsDto = PagingQuery;
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], PagingQuery.prototype, "take", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], PagingQuery.prototype, "skip", void 0);
__decorate([
    (0, class_transformer_1.Transform)(transformStrParm),
    (0, class_validator_1.IsEnum)(constants_1.EventType, { each: true }),
    __metadata("design:type", Array)
], PagingQuery.prototype, "type", void 0);
__decorate([
    (0, class_transformer_1.Transform)(transformStrParm),
    (0, class_validator_1.IsString)({ each: true }),
    __metadata("design:type", Array)
], PagingQuery.prototype, "trader", void 0);

import { isNumberString } from 'class-validator';
import { format, intlFormatDistance, isToday, isWithinInterval, isYesterday, subDays } from 'date-fns';

export const formatDate = (dateValue: number | string | Date) => {
  const value = isNumberString(dateValue) ? Number(dateValue) : dateValue;
  const isWithinLastWeek = isWithinInterval(new Date(dateValue), {
    start: subDays(new Date(), 4),
    end: new Date(),
  });

  if (isWithinLastWeek) {
    const time = format(new Date(value), 'HH:mm');
    const distance = intlFormatDistance(new Date(dateValue), new Date());
    return `${distance} at ${time}`;
  }

  return formatDateString(value);
};

export const formatDateString = (value: number | string | Date) => format(new Date(value), 'HH:mm dd/MM/yyyy');

export const formatDays = (dateValue: number | string | Date) => {
  const value = isNumberString(dateValue) ? Number(dateValue) : dateValue;

  if (isToday(value)) {
    return 'Today';
  }

  if (isYesterday(value)) {
    return 'Yesterday';
  }

  return format(new Date(value), 'dd/MM/yyyy');
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CreateAccountDto, UpdateFollowedTradersDto, UpdateTradeSettingsDto } from '@core/models';
import { mapValues } from 'lodash';
import { computed } from 'mobx';
import { ExtendedModel, Model, _await, fromSnapshot, idProp, model, modelFlow, prop, tProp, types } from 'mobx-keystone';
import { toast } from 'react-toastify';
import { MainModel } from 'store/utils/base-model';
import { success as toastSuccessConfig } from '../../../constants/toast-configs';
import { FollowedTrader } from '../leaderboard/followed-trader';

@model('trade-settings')
export class TradeSettings extends ExtendedModel(MainModel, {
  id: idProp,
  tp: prop<number>(),
  sl: prop<number>(),
  leverage: prop<number>(),
  risk: prop<number>(),
  maxRisk: prop<number>(),
  useTraderRisk: prop<boolean>(),
  isTradingDisabled: prop<boolean>(),
  shouldIncrease: prop<boolean>(),
  shouldDecrease: prop<boolean>(),
}) {}

@model('balance-stats')
export class BalanceStats extends Model({
  balance: prop<string>(),
  initialMarginRisk: prop<string>(),
  maxMarginRisk: prop<string>(),
  equity: prop<string>(),
}) {}

@model('account')
export class Account extends ExtendedModel(MainModel, {
  id: idProp,
  name: prop<string>(),
  traders: tProp(types.array(types.model(FollowedTrader)), () => []),
  tradeSettings: tProp(types.model(TradeSettings)),
  isUpdatingTradeSettings: prop<boolean>(false),
  isUpdatingFollowedTraders: prop<boolean>(false),
  isSandboxEnvironment: prop<boolean>(),
  balanceStats: tProp(types.maybe(types.model(BalanceStats))),
}) {
  protected onInit(): void {
    this.fetchBalanceStats();
  }

  @modelFlow
  *fetchBalanceStats() {
    const payload = yield* _await(this.api.fetch(`account/${this.id}/balance-stats`));
    this.balanceStats = new BalanceStats(payload);
  }

  @modelFlow
  *remove() {
    yield* _await(this.api.delete(`account/${this.id}`));
    this.isDeleted = true;
  }

  @modelFlow
  *updateTradeSettings(updateTradeSettingsDto: UpdateTradeSettingsDto) {
    this.isUpdatingTradeSettings = true;
    try {
      // @ts-ignore
      const payload = mapValues(updateTradeSettingsDto, value => (value === '' ? null : value));
      console.log('payload', payload);
      const tradeSettings = yield* _await(this.api.update(`account/${this.id}/trade-settings`, payload));
      yield this.fetchBalanceStats();
      this.tradeSettings = fromSnapshot(tradeSettings);
      toast('Trader settings updated.', toastSuccessConfig);
    } catch(err) {
      console.log('Update trade settings error', err);
    } finally {
      this.isUpdatingTradeSettings = false;
    }
  }

  @modelFlow
  *updateFollowedTraders(updateFollowedTradersDto: UpdateFollowedTradersDto) {
    this.isUpdatingFollowedTraders = true;
    try {
      const traders = yield* _await(this.api.update(`account/${this.id}/followed-traders`, updateFollowedTradersDto));
      this.traders = fromSnapshot(traders);
      toast('Followers updated.', toastSuccessConfig);
    } catch(err) {
      console.log('Update trade settings error', err);
    } finally {
      this.isUpdatingFollowedTraders = false;
    }
  }

  @computed
  get followedTraderIds() {
    return this.traders.map(({ id }) => id);
  }
}

@model('accounts')
export class Accounts extends ExtendedModel(MainModel, {
  accounts: tProp(types.array(types.model(Account)), () => []),
  isInitiallyFetched: tProp(types.boolean, false),
}) {
  @modelFlow
  *fetchAccounts() {
    const accounts = yield* _await(this.api.fetch('account'));
    this.accounts = fromSnapshot(accounts);
    this.isInitiallyFetched = true;
  }

  @modelFlow
  *createAccount(createAccountDto: CreateAccountDto) {
    const account = yield* _await(this.api.create('account', createAccountDto));
    this.accounts.push(fromSnapshot(account));
  }
}

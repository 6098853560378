import { EventType } from '@core/models';
import { Accounts } from './domain/account/account';
import { Identity } from './domain/identity';
import { Leaderboard } from './domain/leaderboard/leaderboard';
import { FilterChip, TradeEvents } from './domain/trade-events/trade-events';
import { UI, TradeEvents as UITradeEvents } from './domain/ui/ui';
import { RootStore } from './root-store';

export const defaultStore = new RootStore({
  leaderboard: new Leaderboard({ followedTraders: [] }),
  tradeEvents: new TradeEvents({
    events: [],
    count: 0,
    eventTypeFilters: [
      new FilterChip({
        name: EventType.OPEN,
        isSelected: false,
      }),
      new FilterChip({
        name: EventType.CLOSE,
        isSelected: false,
      }),
      new FilterChip({
        name: EventType.INCREASE,
        isSelected: false,
      }),
      new FilterChip({
        name: EventType.DECREASE,
        isSelected: false,
      }),
      new FilterChip({
        name: EventType.REVERSE,
        isSelected: false,
      }),
    ],
  }),
  identity: new Identity({ }),
  accounts: new Accounts({ }),
  ui: new UI({
    tradeEvents: new UITradeEvents({
      skip: 0,
      take: 10,
    }),
  }),
});


import { Box, CircularProgress } from '@mui/joy';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useStore } from 'store/StoreContext';

export const AuthorisedRoute = observer(({ children }: PropsWithChildren) => {
  const { identity } = useStore();

  if (identity.isLoginInFlight) {
    return (
      <Box display={'flex'} justifyContent="center" alignItems={'center'} height={'100vh'}>
        <CircularProgress />
      </Box>
    );
  }

  if (!identity.loggedInUser) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {children}
    </>
  );
});

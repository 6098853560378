import { ExtendedModel, model, tProp, types } from 'mobx-keystone';
import { MainModel } from 'store/utils/base-model';

@model('ui/trade-events')
export class TradeEvents extends ExtendedModel(MainModel, {
  tradersFilter: tProp(types.array(types.string), () => []),
  skip: tProp(types.number),
  take: tProp(types.number),
}) {

}

@model('ui')
export class UI extends ExtendedModel(MainModel, { tradeEvents: tProp(types.model(TradeEvents)) }) {
}


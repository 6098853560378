import { Card, ColorPaletteProp, Typography } from '@mui/joy';

export const Bucket = ({ label, value, descriptionColor }: { label: string; value: string | number; descriptionColor?: ColorPaletteProp; }) => (
  <Card>
    <Typography>
      {label}
    </Typography>
    <Typography color={descriptionColor}>
      {value}
    </Typography>
  </Card>
);

import {
  model,
  Model,
  tProp,
  types,
} from 'mobx-keystone';
import { apiCtx, login$Ctx } from 'store/contexts/api';

@model('main')
export class MainModel extends Model({ isDeleted: tProp(types.boolean, false) }) {
  get api() {
    return apiCtx.get(this);
  }

  get login$() {
    return login$Ctx.get(this);
  }
}

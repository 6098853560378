import { Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { ContentSidebarLayout } from 'components/Layouts/ContentSidebarLayout';
import { TradeEventFilterTray } from 'components/TradeEventFilterTray';
import { TradeEventTable } from 'components/TradeEventTable/TradeEventTable';
import { observer } from 'mobx-react-lite';

export const TradeEvents = observer(() => {
  const tradeEventFilterTray = <TradeEventFilterTray />;
  const tradeEventTable = <TradeEventTable />;
  const isLarge = useMediaQuery('(min-width:600px)');

  const mobileLayout = (
    <Tabs defaultValue={0}>
      <TabList>
        <Tab>
          Trade Events
        </Tab>
        <Tab>
          Filter
        </Tab>
      </TabList>
      <TabPanel value={0}>
        {tradeEventTable}
      </TabPanel>
      <TabPanel value={1}>
        {tradeEventFilterTray}
      </TabPanel>
    </Tabs>
  );

  return (
    <ContentSidebarLayout title="Trade events" sidebarContent={isLarge && tradeEventFilterTray}>
      {isLarge ? tradeEventTable : mobileLayout}
    </ContentSidebarLayout>
  );
});

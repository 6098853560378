import { computed } from 'mobx';
import {
  ExtendedModel,
  idProp,
  model,
  prop,
} from 'mobx-keystone';
import { MainModel } from 'store/utils/base-model';

@model('user')
export class User extends ExtendedModel(MainModel, {
  id: idProp,
  email: prop<string>(),
  token: prop<string>(),
  firstName: prop<string>(),
  lastName: prop<string>(),
}) {
  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

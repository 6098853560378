import PersonRounded from '@mui/icons-material/PersonRounded';
import { List, ListItem, ListItemDecorator, ListSubheader, Typography } from '@mui/joy';
import { Link } from 'components/Link';
import { ToggleFollowerButton } from 'components/ToggleFollower/ToggleFollower';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useStore } from 'store/StoreContext';
import { FollowedTrader } from 'store/domain/leaderboard/followed-trader';

export const FollowedTraderList = observer(() => {
  const { leaderboard: { activeTraders, disabledTraders } } = useStore();

  return (
    <List>
      {activeTraders.length > 0 && <ListSubheader>Active</ListSubheader>}
      {activeTraders
        .filter(({ isDeleted }) => !isDeleted)
        .map(trader => <FollowedTraderItem key={trader.id} trader={trader} />)}
      {disabledTraders.length > 0 && <ListSubheader>Disabled</ListSubheader>}
      {disabledTraders
        .filter(({ isDeleted }) => !isDeleted)
        .map(trader => <FollowedTraderItem key={trader.id} trader={trader} />)}
    </List>
  );
});

export const FollowedTraderItem = observer(({ trader }: PropsWithChildren<{ trader: FollowedTrader }>) => (
  <ListItem endAction={<ToggleFollowerButton trader={trader} isIconButton />}>
    <ListItemDecorator>
      <PersonRounded />
    </ListItemDecorator>
    <Link to={`/main/trader-positions/${trader.id}`}>
      <Typography level="title-sm">
        {trader.name}
      </Typography>
    </Link>
  </ListItem>
));

import { CreateUserDto, LoginUserDto } from '@core/models';
import HttpError from 'errors/http.error';
import { isEmpty } from 'lodash';
import {
  ExtendedModel,
  _await,
  fromSnapshot,
  getRoot,
  model,
  modelFlow,
  tProp,
  types,
} from 'mobx-keystone';
import { toast } from 'react-toastify';
import { MainModel } from 'store/utils/base-model';
import { error as toastErrorConfig } from '../../../constants/toast-configs';
import { User } from './user';

@model('identity')
export class Identity extends ExtendedModel(MainModel, {
  loggedInUser: tProp(types.maybe(types.model(User))),
  isLoginAttempted: tProp(types.maybe(types.boolean)),
  loginErrorMessage: tProp(types.maybe(types.string)),
  isLoginInFlight: tProp(types.maybe(types.boolean)),
}) {
  protected onInit(): void {
    this.tokenLogin();
  }

  @modelFlow
  *login(loginPayload?: LoginUserDto) {
    try {
      this.isLoginInFlight = true;
      const url = loginPayload ? 'identity/login' : 'identity/token';
      const user = yield* _await(this.api.fetch(url, loginPayload));
      this.loggedInUser = fromSnapshot(user);
      this.storeToken();
      const root = getRoot<{ onLogin: () => void }>(this);
      root.onLogin();
    } catch (error) {
      if (error instanceof HttpError) {
        toast(error.message, toastErrorConfig);
      }
      return error;
    } finally {
      this.isLoginInFlight = false;
      this.isLoginAttempted = true;
    }
  }

  tokenLogin() {
    const token = localStorage.getItem('TOKEN');
    if (isEmpty(token)) {
      return;
    }
    this.login();
  }

  @modelFlow
  *signup(signupPayload: CreateUserDto) {
    try {
      const user = yield* _await(this.api.create('identity/signup', signupPayload));
      this.loggedInUser = fromSnapshot(user);
      this.storeToken();
    } catch (error) {
      if (error instanceof HttpError) {
        toast(error.message, toastErrorConfig);
      }
      return error;
    } finally {
      this.isLoginAttempted = true;
    }
  }

  @modelFlow
  *logout() {
    try {
      try {
        yield _await(this.api.delete('identity/logout'));
      } catch(err) {
        console.log('err', err);
      }
      this.loggedInUser = undefined;
    } catch (error) {
      if (error instanceof HttpError) {
        toast(error.message, toastErrorConfig);
      }
    } finally {
      this.isLoginAttempted = false;
      this.deleteToken();
    }
  }

  private storeToken() {
    localStorage.setItem('TOKEN', this.loggedInUser?.token as string);
  }

  private deleteToken() {
    localStorage.removeItem('TOKEN');
  }
}

export class HttpError extends Error {
  status: number;
  constructor(status: number, message: string) {
    super('Http error');
    this.status = status;
    this.message = message;
  }
}

export const isHttpError = (
  error: Error | HttpError | unknown,
  status?: number,
): boolean => error instanceof HttpError && error.status === status;

export default HttpError;

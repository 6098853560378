import HttpError from 'errors/http.error';

// @TODO: should be strongly typed and able to accept DTO classes
type Params = any

export class ApiClient {
  host = process.env.NODE_ENV === 'production' ? '//api.modetrader.com/api' : '//localhost:4000';
  auth = '';

  private get headers() {
    const token = localStorage.getItem('TOKEN');
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };
  }

  async fetch(route: string, query?: Params ) {
    const urlSearchParams = `?${new URLSearchParams(query)}`;
    const url = `${this.host}/${route}${query ? urlSearchParams : ''}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: this.headers,
    });
    if (response && !response.ok) {
      const errBody = await response.json();
      throw new HttpError(response.status, errBody.message);
    }
    return await response.json();
  }

  async create(route: string, body?: Params) {
    const url = `${this.host}/${route}`;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.headers,
    });
    if (response && !response.ok) {
      const errBody = await response.json();
      throw new HttpError(response.status, errBody.message);
    }
    return await response.json();
  }

  async delete(route: string) {
    const url = `${this.host}/${route}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: this.headers,
    });
    if (response && !response.ok) {
      const errBody = await response.json();
      throw new HttpError(response.status, errBody.message);
    }
    try {
      return await response.json();
    } catch(e) {
      console.log('delete encode JSON error', e);
      return;
    }
  }

  async update(route: string, body?: Params) {
    const url = `${this.host}/${route}`;
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: this.headers,
    });
    if (response && !response.ok) {
      const errBody = await response.json();
      throw new HttpError(response.status, errBody.message);
    }
    try {
      return await response.json();
    } catch(e) {
      console.log('delete encode JSON error', e);
      return;
    }
  }
}

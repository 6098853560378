import { PropsWithChildren } from 'react';
import { Box } from '@mui/joy';
import { PageHeader, PageHeaderProps } from 'components/PageHeader';

export type ContentStandardLayoutProps = PropsWithChildren & PageHeaderProps

export const ContentStandardLayout = ({ children, title, subTitle, headerContentRight }: ContentStandardLayoutProps) => (
  <>
    <Box sx={{ px: { xs: 2 } }}>
      <PageHeader
        title={title}
        subTitle={subTitle}
        headerContentRight={headerContentRight}
      />
    </Box>

    <Box sx={{
      flex: 1,
      overflow: 'auto',
      px: { xs: 2 },
    }}
    >
      {children}
    </Box>
  </>
);

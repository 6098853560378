import { FollowNewTraderModal } from 'components/FollowNewTraderModal/FollowNewTraderModal';
import { FollowedTraderList } from 'components/FollowedTraderList';
import { ContentStandardLayout } from 'components/Layouts/ContentStandardLayout';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStore } from 'store/StoreContext';

export const FollowedTraders = observer(() => {
  const { leaderboard } = useStore();

  useEffect(() => {
    leaderboard.fetchTraders();
  }, [leaderboard]);

  return (
    <ContentStandardLayout
      title="Followed Traders"
      headerContentRight={<FollowNewTraderModal />}
    >
      <FollowedTraderList />
    </ContentStandardLayout>
  );
});

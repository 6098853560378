import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store/StoreContext';

interface FormElements extends HTMLFormControlsCollection {
  firstName: HTMLInputElement;
  lastName: HTMLInputElement;
  email: HTMLInputElement;
  password: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export const SignupForm = () => {
  const navigate = useNavigate();
  const { identity } = useStore();

  const handleSubmit = async (event: FormEvent<SignInFormElement>) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    const data = {
      firstName: formElements.firstName.value,
      lastName: formElements.lastName.value,
      email: formElements.email.value,
      password: formElements.password.value,
    };
    const error = await identity.signup(data);
    if (!error) {
      navigate('/main/followed-traders');
    }
  };

  return (
    <Box
      component="main"
      sx={{
        my: 'auto',
        py: 2,
        pb: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: 400,
        maxWidth: '100%',
        mx: 'auto',
        borderRadius: 'sm',
        '& form': {
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        },
        ['& .MuiFormLabel-asterisk']: { visibility: 'hidden' },
      }}
    >
      <Typography component="h1" level="h3">
        Sign up
      </Typography>

      <Stack gap={4} sx={{ mt: 2 }}>
        <form onSubmit={handleSubmit}>
          <FormControl required>
            <FormLabel>First name</FormLabel>
            <Input type="text" name="firstName" />
          </FormControl>
          <FormControl required>
            <FormLabel>Last name</FormLabel>
            <Input type="text" name="lastName" />
          </FormControl>
          <FormControl required>
            <FormLabel>Email</FormLabel>
            <Input type="email" name="email" />
          </FormControl>
          <FormControl required>
            <FormLabel>Password</FormLabel>
            <Input type="password" name="password" />
          </FormControl>
          <Stack gap={4} sx={{ mt: 2 }}>
            <Button type="submit" fullWidth>
              Sign in
            </Button>
          </Stack>
        </form>
      </Stack>
      <Divider
        sx={theme => ({
          [theme.getColorSchemeSelector('light')]: {
            color: {
              xs: '#FFF',
              md: 'text.tertiary',
            },
          },
        })}
      >
        or
      </Divider>
      <Button
        onClick={() => navigate('/login')}
        variant="soft"
        color="neutral"
        fullWidth
      >
        Switch to login
      </Button>
    </Box>
  );
};

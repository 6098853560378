import { Bounce, ToastOptions } from 'react-toastify';

export const common: ToastOptions = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
  transition: Bounce,
};


export const error: ToastOptions = { ...common };

export const success: ToastOptions = { ...common };

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventType = void 0;
var EventType;
(function (EventType) {
    EventType["OPEN"] = "open";
    EventType["CLOSE"] = "close";
    EventType["DECREASE"] = "decrease";
    EventType["INCREASE"] = "increase";
    EventType["REVERSE"] = "reverse";
})(EventType || (exports.EventType = EventType = {}));

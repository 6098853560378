import { AccountDetails } from 'components/AccountDetails/AccountDetails';
import { DeleteAccountButton } from 'components/DeleteAccount';
import { ContentStandardLayout } from 'components/Layouts/ContentStandardLayout';
import { useAccount } from 'hooks/useAccount';
import { observer } from 'mobx-react-lite';

export const Account = observer(() => {
  const account = useAccount();

  if (!account) {
    return null;
  }

  return (
    <ContentStandardLayout title="Account" subTitle={`${account.name} (${account.isSandboxEnvironment ? 'sandbox' : 'live'})`} headerContentRight={<DeleteAccountButton account={account} />}>
      <AccountDetails account={account} />
    </ContentStandardLayout>
  );
});



import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

import { PropsWithChildren, ReactNode } from 'react';

export type PageHeaderProps = PropsWithChildren<{ title: string, subTitle?: string; headerContentRight?: ReactNode }>;

export const PageHeader = ({ title, subTitle, headerContentRight }: PageHeaderProps) => (
  <Box
    sx={{
      display: 'flex',
      mb: 1,
      gap: 1,
      flexDirection: {
        xs: 'column',
        sm: 'row',
      },
      alignItems: {
        xs: 'start',
        sm: 'center',
      },
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    }}
  >
    <div>
      <Typography level="h2" component="h1">
        {title}
      </Typography>
      {subTitle && (
        <Typography level="h4" component="h4" color="neutral">
          {subTitle}
        </Typography>
      )}
    </div>
    {headerContentRight}
  </Box>
);

import { AccountList } from 'components/AccountList/AccountList';
import { AccountModal } from 'components/AccountModal';
import { ContentStandardLayout } from 'components/Layouts/ContentStandardLayout';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStore } from 'store/StoreContext';

export const Accounts = observer(() => {
  const { accounts } = useStore();

  useEffect(() => {
    accounts.fetchAccounts();
  }, [accounts]);

  return (
    <ContentStandardLayout
      title="Accounts"
      headerContentRight={<AccountModal />}
    >
      <AccountList />
    </ContentStandardLayout>
  );
});

import { Box, Card, Chip, Divider, Typography } from '@mui/joy';
import { Check } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useStore } from 'store/StoreContext';

// @TODO: filter for traders
// filter for event types

export const TradeEventFilterTray = observer(() => (
  <>
    <EventTypeFilter />
    <TraderFilter />
  </>
));

export const EventTypeFilter = observer(() => {
  const {
    tradeEvents: { eventTypeFilters },
    tradeEvents,
  } = useStore();

  return (
    <Card>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Event type filter</Typography>
      </Box>
      <Divider />
      {eventTypeFilters.map(filterChip => (
        <FilterChip
          key={filterChip.name}
          label={filterChip.name}
          isSelected={filterChip.isSelected}
          onClick={() => {
            filterChip.toggle();
            tradeEvents.fetchEvents(true);
          }}
        />
      ))}
    </Card>
  );
});

export const TraderFilter = observer(() => {
  const {
    leaderboard: { activeTraders },
    tradeEvents,
  } = useStore();

  return (
    <Card>
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Trader filter</Typography>
      </Box>
      <Divider />
      {activeTraders.map(trader => (
        <FilterChip
          key={trader.id}
          label={trader.name}
          isSelected={trader.isSelected}
          onClick={() => {
            trader.toggle();
            tradeEvents.fetchEvents(true);
          }}
        />
      ))}
    </Card>
  );
});

export const FilterChip = ({ label, isSelected, onClick }: PropsWithChildren<{
  label: string,
  isSelected?: boolean,
  onClick: () => void
}>) => (
  <Chip
    variant="outlined"
    color="neutral"
    size="lg"
    endDecorator={isSelected ? <Check /> : <></>}
    onClick={() => onClick()}
  >
    {label}
  </Chip>
);

import { Button, Switch } from '@mui/joy';
import DialogTitle from '@mui/joy/DialogTitle';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { success } from 'constants/toast-configs';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FollowedTrader } from 'store/domain/leaderboard/followed-trader';

export const ToggleFollowerButton = observer(({ trader }: PropsWithChildren<{ trader: FollowedTrader }> & { isIconButton?: boolean }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const confirmToggle = async () => {
    await trader.toggleActiveState();
    setOpen(false);
    navigate('/main/followed-traders');
    toast(`Trader ${trader.name} successfully deleted.`, success);
  };

  return (
    <React.Fragment>
      <Switch checked={trader.isActive} onChange={() => setOpen(true)} />
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>
            Confirm switching
            {' '}
            {trader.isActive ? 'off' : 'on'}
            {' '}
            events for trader
            {' '}
            {trader.name}
          </DialogTitle>
          <Button color={trader.isActive ? 'danger' : 'success'} type="submit" onClick={confirmToggle}>Confirm</Button>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
});

import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';
import { ToastContainer } from 'react-toastify';

import { MasterRouter } from 'routes';

export default function App() {
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <MasterRouter />
      <ToastContainer />
    </CssVarsProvider>
  );
}

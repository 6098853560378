import { UpdateTradeSettingsDto } from '@core/models';
import { Box, Button, Card, CardActions, CardOverflow, Divider, FormControl, FormHelperText, FormLabel, Input, Stack, Switch, Typography } from '@mui/joy';
import { getSnapshot } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AccountProp } from 'types/account/account';

export const TradeSettings = observer(({ account }: AccountProp) => {
  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
  } = useForm<UpdateTradeSettingsDto>({ defaultValues: getSnapshot(account.tradeSettings) });


  const onSubmit: SubmitHandler<UpdateTradeSettingsDto> = async data => {
    data.risk = Number(data.risk);
    data.maxRisk = Number(data.maxRisk);
    await account?.updateTradeSettings(data);
    reset(getSnapshot(account.tradeSettings));
  };

  useEffect(() => {
    reset(getSnapshot(account.tradeSettings));
  }, [account]);

  const sx = {
    width: 300,
    justifyContent: 'space-between',
    gap: 3,
  };

  return (
    <Card>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ mb: 2 }}>
          <Typography level="title-md">Trade settings</Typography>
        </Box>
        <Divider />
        <Stack direction="row" spacing={4} paddingY={3}>
          <Stack spacing={4} >
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Mirror trader risk</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Take proportionately same risk as lead trader</FormHelperText>
              </div>
              <Controller
                name="useTraderRisk"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch checked={value} onChange={onChange} size="lg" sx={{ width: 100 }} />
                )}
              />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Risk</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>For opening a position</FormHelperText>
              </div>
              <Input {...register('risk')} type="number" slotProps={{ input: { step: 0.1 } }} startDecorator={'%'} required sx={{ width: 100 }} />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Max risk</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Maximum total allowable</FormHelperText>
              </div>
              <Input {...register('maxRisk')} type="number" slotProps={{ input: { step: 0.1 } }} startDecorator={'%'} required sx={{ width: 100 }} />
            </FormControl>
            <FormControl orientation="horizontal" sx={sx}>
              <div>
                <FormLabel>Leverage</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Set leverage explicitly</FormHelperText>
              </div>
              <Input {...register('leverage')} type="number" endDecorator="x" sx={{ width: 100 }} />
            </FormControl>
          </Stack>
        </Stack>
        <CardOverflow sx={{
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
        >
          <CardActions sx={{
            alignSelf: 'flex-end',
            pt: 2,
            paddingBottom: 0,
          }}
          >
            <Button size="md" variant="solid" type="submit" disabled={!formState.isDirty} loading={account.isUpdatingTradeSettings}>
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      </form>
    </Card>
  );
});

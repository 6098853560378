import { Box, CircularProgress, Link, Stack } from '@mui/joy';
import { Bucket } from 'components/Bucket';
import { ContentStandardLayout } from 'components/Layouts/ContentStandardLayout';
import { TraderPositionsTable } from 'components/TraderPositionsTable/TraderPositionsTable';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'store/StoreContext';
import { FollowedTrader } from 'store/domain/leaderboard/followed-trader';

export const TraderDetails = observer(() => {
  const { leaderboard } = useStore();
  const { id } = useParams();
  const trader = leaderboard.findTraderById(id);

  return (
    <ContentStandardLayout title="Trader Details" subTitle={trader ? trader.name : ''}>
      {trader && <TraderDetailsContent trader={trader} />}
    </ContentStandardLayout>
  );
});

export const TraderDetailsContent = observer(({ trader }: { trader: FollowedTrader } ) => {
  useEffect(() => {
    trader.fetchStats();
    trader.fetchPositions(true);

    const interval = setInterval(() => {
      trader.fetchPositions(true);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [trader]);

  if (!trader || trader.isFetchingPositions || trader.isFetchingStats) {
    return (
      <Box display={'flex'} justifyContent="center" alignItems={'center'} height={'100%'}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack gap={3}>
      <TraderStats trader={trader} />
      <TraderPositionsTable trader={trader} />
    </Stack>
  );
});

export const TraderStats = observer(({ trader }: { trader: FollowedTrader }) => (
  <>
    {trader && trader.stats && (
      <Stack gap={3} direction="row" flexWrap="wrap">
        <Bucket label="Estimated trader balance" value={trader.stats.balance ? `${trader.stats.formattedBalance}` : 'Cannot calculate.'} />
        <Bucket label="Win rate" value={trader.stats.formattedWinRate} />
        <Bucket label="Avg. win %" value={trader.stats.formattedAvgWinPercent} />
        <Bucket label="Avg. loss %" value={trader.stats.formattedAvgLossPercent} />
        <Bucket label="Cum. percent" value={trader.stats.formattedTotalPercent} descriptionColor={trader.stats.isProfitable ? 'success' : 'danger'} />
        <Bucket label="Cum. PNL" value={trader.stats.formattedPnl} descriptionColor={trader.stats.isProfitable ? 'success' : 'danger'} />
        <Link href={trader.binanceUrl} target="_blank">
          Binance link
        </Link>
      </Stack>
    )}
  </>
));

